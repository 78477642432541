<template>
  <!-- The-trip-page -->
  <Navbar />
  <!-- <section class="breadcrumb-area bread-bg">
      <div class="breadcrumb-wrap">
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-lg-6">
                      <div class="breadcrumb-content">
                          <div class="section-heading">
                              <h2 class="sec__title text-white">Complete Booking</h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="bread-svg-box">
          <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
      </div>
  </section> -->
  <section
    id="hero"
    class="background-image hero-background-image"
    data-background="url(assets/images/abc.jpg)"
  >
    <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
      <div class="intro_title">
        <h3
          class="mt-0 text-capitalize"
          style="font-weight: 700; font-size: 3rem"
        >
          Complete Booking
        </h3>
        <br />
      </div>
    </div>
  </section>
  <main>
    <div class="container margin_60">
      <div class="main_title">
        <!-- <h2 class="mb-2 text-capitalize">Order Confirm and Payment</h2> -->
      </div>

      <div class="row justify-content-center" v-if="!isLoading">
        <div
          class="col-md-4 pe-4 wow zoomIn"
          data-wow-delay="0.2s"
          v-for="(booking, index) in listOfBooking"
          :key="index"
        >
          <div class="box">
            <!-- <img src="@/assets/images/check.png" style="width: 100%;height: 75px;object-fit: contain;"> -->
            <h4 class="text-center mt-3" style="color: white">
              {{ labbelText }} <br />
              <!-- <small class="text-white">We have emailed you the receipt</small> -->
            </h4>
            <br />

            <main class="ticket-system">
              <!-- end of pending -->
              <section class="hero1-background-image">
                <div class="waiting-loader" v-if="pending">
                  <div class="loader-wrapper main-loader">
                    <div class="spin-loader"></div>
                    <div class="loader rspin">
                      <span class="c"></span>
                      <span class="d spin"><span class="e"></span></span>
                      <span class="r r1"></span>
                      <span class="r r2"></span>
                      <span class="r r3"></span>
                      <span class="r r4"></span>
                    </div>
                  </div>
                  <div class="spin-loader1"></div>
                </div>

                <div class="tick-animation" v-if="verified">
                  <div class="wrapper">
                    <svg
                      class="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        class="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        class="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                </div>

                <div
                  class="failure-animation"
                  v-if="current_status == 'failure'"
                >
                  <div class="wrapper">
                    <section class="c-container failure-button">
                      <div
                        class="o-circle c-container__circle o-circle__sign--failure"
                      >
                        <div class="o-circle__sign"></div>
                      </div>
                    </section>
                  </div>
                </div>
              </section>

              <div
                class="ticket"
                style="background-color: #fbd127"
                v-if="printable"
              >
                <div class="top">
                  <br />
                  <h1 class="title">
                    Wait a second, your ticket is being printed
                  </h1>
                  <div class="printer" />
                </div>

                <div class="receipts-wrapper">
                  <div class="receipts">
                    <div class="receipt">
                      <h2 class="heavy-font" style="font-size: 19px">
                        {{ booking.trip.vehicle.name }}
                      </h2>
                      <!-- <img src="@/assets/logo/logo.png" style="width: 100%;height: 40px;object-fit: contain;" /> -->

                      <div class="route">
                        <h2 class="heavy-font">{{ booking.from_code }}</h2>

                        <img
                          src="@/assets/images/bus_logo.png"
                          style="width: 100%; height: 65px; object-fit: contain"
                        />

                        <h2 class="heavy-font">{{ booking.to_code }}</h2>
                      </div>
                      <div class="details">
                        <div class="item">
                          <span class="bold-font">Bus Reg No</span>
                          <h3 class="light-font" style="font-size: 15px">
                            {{ booking.trip.vehicle.registration_number }}
                          </h3>
                        </div>
                        <div class="item" style="padding-left: 8%">
                          <span class="bold-font">Reporting Time</span>
                          <h3 class="light-font" style="font-size: 15px">
                            {{ booking.trip.reporting_time }}
                          </h3>
                        </div>

                        <div class="item">
                          <span class="bold-font">Departure Time</span>
                          <h3 class="light-font" style="font-size: 15px">
                            {{ booking.trip.departing_time }}
                          </h3>
                        </div>

                        <div class="item">
                          <span class="bold-font">Passenger</span>
                          <h3 class="light-font" style="font-size: 15px">
                            {{ booking.passenger.name }}
                          </h3>
                        </div>
                        <div class="item">
                          <span class="bold-font">Phone No.</span>
                          <h3 class="light-font" style="font-size: 15px">
                            {{ booking.passenger.phone_number }}
                          </h3>
                        </div>

                        <div class="item">
                          <span class="bold-font">Email</span>
                          <h3 class="light-font" style="font-size: 15px">
                            {{ booking.passenger.email }}
                          </h3>
                        </div>

                        <div class="item">
                          <span class="bold-font">Age</span>
                          <h3 class="light-font" style="font-size: 15px">
                            {{ booking.passenger.age_group }}
                          </h3>
                        </div>

                        <div class="item">
                          <span class="bold-font">Seat</span>
                          <h3 class="light-font" style="font-size: 15px">
                            {{ booking.seat_number }}
                          </h3>
                        </div>
                        <div class="item">
                          <span class="bold-font">Baggage</span>
                          <h3 class="light-font" style="font-size: 15px">No</h3>
                        </div>
                      </div>

                      <h3 class="bold-font money" style="padding-left: 10%">
                        Total Fare: Tsh <span>{{ booking.trip.fare }}</span>
                      </h3>
                    </div>

                    <div class="receipt qr-code" style="height: 8%">
                      <div class="smaller_screens">
                        <ul style="list-style-type: none">
                          <li>
                            <vue-qrcode
                              :value="dataUrl"
                              @change="onDataUrlChange"
                            />
                          </li>
                          <li>
                            <div class="description">
                              <h2 class="bold_font" style="font-size: 12px">
                                REF:{{ booking.booking_number }}
                              </h2>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div class="above_screens">
                        <vue-qrcode
                          :value="dataUrl"
                          @change="onDataUrlChange"
                        />
                        <div class="description">
                          <h2 class="bold_font" style="font-size: 20px">
                            REF:
                          </h2>

                          <p>{{ booking.booking_number }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <!-- payment ends here -->
          </div>

          <p class="text-center mt-3" v-if="printable">
            <!-- <a href="#" class="d-inline-block text-dark fw-bolder"><img src="@/assets/images/pdf.png" style="width: 100%;height: 25px;object-fit: contain;"> PDF</a> -->

            <!-- <a href="#" class="d-inline-block text-dark fw-bolder" @click.prevent="printDiv">
              <img src="@/assets/images/print.png" style="width: 100%;height: 25px;object-fit: contain;" />
              Print</a> -->

            <a
              href="#"
              class="d-inline-block text-dark fw-bolder"
              @click.prevent="pdfConvert"
            >
              <img
                src="@/assets/images/print.png"
                style="width: 100%; height: 25px; object-fit: contain"
              />
              PDF</a
            >

            <!-- <a href="#" class="d-inline-block text-dark fw-bolder" @click.prevent="generateReport">
              <img src="@/assets/images/print.png" style="width: 100%;height: 25px;object-fit: contain;" />
              Print</a> -->

            <!-- <button class="print-button"><span class="print-icon"></span></button> -->
          </p>
        </div>
        <div class="row justify-content-center pt-5">
          <div class="text-center" v-if="printable">
            <!-- <button type="button" class="btn btn-secondary mr-1" @click="printTicket()">Print </button> -->
            <button
              type="button"
              class="btn btn-secondary ml-1"
              @click="bookAgain()"
              style="margin-left: 2%"
            >
              Book Again
            </button>
          </div>

          <!-- <div class="text-center"> -->

          <div class="text-center" v-if="current_status === 'failure'">
            <!-- <button type="button" class="btn btn-secondary mr-1" @click="printTicket()">Print </button> -->
            <button
              type="button"
              class="btn btn-secondary ml-1"
              @click="retry()"
              style="margin-left: 2%"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <Loading />
      </div>
    </div>
    <!-- End container -->
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Loading from "@/views/custom/Loading.vue";

import { jsPDF } from "jspdf";

import VueQrcode from "vue-qrcode";

export default {
  components: { Navbar, Loading, VueQrcode },
  data() {
    return {
      labbelText: "",
      dataUrl: "",
      pending: "",
      printable: "",
      ref_no: "",
      isLoading: true,
      listOfBooking: [],
      listOfPaymentModes: [],
      checkoutSuccess: localStorage.getItem("checkout_success"),
      transctionNumber: localStorage.getItem("transactionId"),
      form: {
        provider: "",
        phone_number: "",
        booking_number: "",
      },
      paymentStatus: [],
      error: "",
    };
  },
  methods: {
    pdfConvert() {
      // this.$router.push({ name: "downloadPdf" });
      this.$router.push({
        name: "downloadPdf",
        params: { dataUrl: this.dataUrl },
      });

    },

    retry() {
      // modify the truck key frame here
      this.stopHttpRequest();
      // Add animation styles for the truck
      const style = document.createElement("style");
      style.innerHTML = `
    :root {
  --loaderSize: 75px;
  --radThickness: calc(var(--loaderSize) / 10);
  --spinSpeed: 750ms;
  --backgroundColor: #eee;
  --forgroundColor: #ccc;
  --masksColor: #fff;
  --middleCircleSize: 68%;
}

/* Mixins */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rspin {
  to {
    transform: rotate(-360deg);
  }
}

/* Animations */
.spin {
  animation: spin var(--spinSpeed) infinite linear;
}

.rspin {
  animation: rspin calc(var(--spinSpeed) * 3) infinite linear;
}

/* Loader styles */
.loader {
  background-color: var(--backgroundColor);
  border-radius: 100%;
  position: relative;
  height: var(--loaderSize);
  width: var(--loaderSize);
  overflow: hidden;
}

.loader .c {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: calc(-1 * var(--middleCircleSize) / 2) 0 0 calc(-1 * var(--middleCircleSize) / 2);
  width: var(--middleCircleSize);
  height: var(--middleCircleSize);
  background-color: var(--masksColor);
  border-radius: 100%;
  z-index: 3;
}

.loader .d {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loader .d .e {
  --thisWidth: calc(var(--loaderSize) * 0.2);
  position: absolute;
  top: 1%;
  left: 50%;
  margin: 0 0 0 calc(-1 * var(--thisWidth) / 2);
  height: 14px;
  width: var(--thisWidth);
  transform: rotate(10deg) skew(20deg);
  border-radius: 3px;
  background: #999;
}

.loader .r {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: -1px;
  bottom: -1px;
  margin-left: calc(-1 * var(--radThickness) / 2);
  background-color: var(--masksColor);
  width: var(--radThickness);
}

.loader .r1 {
  transform: rotate(0deg);
}

.loader .r2 {
  transform: rotate(45deg);
}

.loader .r3 {
  transform: rotate(90deg);
}

.loader .r4 {
  transform: rotate(135deg);
}
  `;

      // Append the style element to the document's head
      document.head.appendChild(style);

      // /////////////////////////
      this.pending = true;
      this.verified = false;
      this.current_status = "pending";

      this.labbelText = "Payment -Pending";
      document.title = `Payment Verification`;

      this.getPaymentModes();
      this.startHttpRequest();
      if (localStorage.getItem("bookings")) {
        this.listOfBooking = JSON.parse(localStorage.getItem("bookings"));

        let newPaymentStatus = {
          payment_status: [],
        };
        this.listOfBooking.forEach((bookingObj, index) => {
          let newBooking = {
            booking_number: bookingObj.booking_number,
          };
          newPaymentStatus.payment_status.push(newBooking);
        });
        this.paymentStatus.push(newPaymentStatus);
      }
    },
    printDiv() {
      const printableContent = this.$refs.printableDiv;

      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
<html>
<head>
 <style>
 /* -------------------------------------
GLOBAL
A very basic CSS reset
------------------------------------- */
* {
margin: 0;
padding: 0;
font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
box-sizing: border-box;
font-size: 14px;
}
img {
max-width: 100%;
}
body {
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: none;
width: 100% !important;
height: 100%;
line-height: 1.6;
}
/* Let's make sure all tables have defaults */
table td {
vertical-align: top;
}
/* -------------------------------------
BODY & CONTAINER
------------------------------------- */
body {
background-color: #f6f6f6;
}
.body-wrap {
background-color: #f6f6f6;
width: 100%;
}
.container {
display: block !important;
max-width: 600px !important;
margin: 0 auto !important;
/* makes it centered */
clear: both !important;
}
.content {
max-width: 600px;
margin: 0 auto;
display: block;
padding: 20px;
}
/* -------------------------------------
HEADER, FOOTER, MAIN
------------------------------------- */
.main {
background: #fff;
border: 1px solid #e9e9e9;
border-radius: 3px;
}
.content-wrap {
padding: 20px;
}
.content-block {
padding: 0 0 20px;
}
.header {
width: 100%;
margin-bottom: 20px;
}
.footer {
width: 100%;
clear: both;
color: #999;
padding: 20px;
}
.footer a {
color: #999;
}
.footer p, .footer a, .footer unsubscribe, .footer td {
font-size: 12px;
}
/* -------------------------------------
TYPOGRAPHY
------------------------------------- */
h1, h2, h3 {
font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
color: #000;
margin: 40px 0 0;
line-height: 1.2;
font-weight: 400;
}
h1 {
font-size: 32px;
font-weight: 500;
}
h2 {
font-size: 24px;
}
h3 {
font-size: 18px;
}
h4 {
font-size: 14px;
font-weight: 600;
}
p, ul, ol {
margin-bottom: 10px;
font-weight: normal;
}
p li, ul li, ol li {
margin-left: 5px;
list-style-position: inside;
}
/* -------------------------------------
LINKS & BUTTONS
------------------------------------- */
a {
color: #1ab394;
text-decoration: underline;
}
.btn-primary {
text-decoration: none;
color: #FFF;
background-color: #1ab394;
border: solid #1ab394;
border-width: 5px 10px;
line-height: 2;
font-weight: bold;
text-align: center;
cursor: pointer;
display: inline-block;
border-radius: 5px;
text-transform: capitalize;
}
/* -------------------------------------
OTHER STYLES THAT MIGHT BE USEFUL
------------------------------------- */
.last {
margin-bottom: 0;
}
.first {
margin-top: 0;
}
.aligncenter {
text-align: center;
}
.alignright {
text-align: right;
}
.alignleft {
text-align: left;
}
.clear {
clear: both;
}
/* -------------------------------------
ALERTS
Change the class depending on warning email, good email or bad email
------------------------------------- */
.alert {
font-size: 16px;
color: #fff;
font-weight: 500;
padding: 20px;
text-align: center;
border-radius: 3px 3px 0 0;
}
.alert a {
color: #fff;
text-decoration: none;
font-weight: 500;
font-size: 16px;
}
.alert.alert-warning {
background: #f8ac59;
}
.alert.alert-bad {
background: #ed5565;
}
.alert.alert-good {
background: #1ab394;
}
/* -------------------------------------
INVOICE
Styles for the billing table
------------------------------------- */
.invoice {
margin: 40px auto;
text-align: left;
width: 80%;
}
.invoice td {
padding: 5px 0;
}
.invoice .invoice-items {
width: 100%;
}
.invoice .invoice-items td {
border-top: #eee 1px solid;
}
.invoice .invoice-items .total td {
border-top: 2px solid #333;
border-bottom: 2px solid #333;
font-weight: 700;
}
/* -------------------------------------
RESPONSIVE AND MOBILE FRIENDLY STYLES
------------------------------------- */
@media only screen and (max-width: 640px) {
h1, h2, h3, h4 {
font-weight: 600 !important;
margin: 20px 0 5px !important;
}
h1 {
font-size: 22px !important;
}
h2 {
font-size: 18px !important;
}
h3 {
font-size: 16px !important;
}
.container {
width: 100% !important;
}
.content, .content-wrap {
padding: 10px !important;
}
.invoice {
width: 100% !important;
}
}
// for qrcode
 </style>
 '\x3Cscript type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js">\x3C/script>'
</head>
<body>
  <table class="body-wrap">
<tbody><tr>
<td></td>
<td class="container" width="600">
    <div class="content" >
          
        <table class="main" width="50%" cellpadding="0" cellspacing="0" >
       
            <tbody><tr>
            
                <td class="content-wrap aligncenter">
                
                    <table width="100%" cellpadding="0" cellspacing="0">
                        <tbody>
                        
                        <tr >
                          
                            <td class="content-block">                           
                                <table class="invoice">
                                    <tbody>
                                 
                                      <tr >
                                       
                                        <td><b>Personal Details</b></td>
                                      
                                    </tr>
                                    <tr>
                                        <td>
                                            <table class="invoice-items" cellpadding="0" cellspacing="0">
                                                <tbody><tr>
                                                    <td>Name</td>
                                                    <td class="alignright">${this.listOfBooking[0].passenger.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone</td>
                                                    <td class="alignright">${this.listOfBooking[0].passenger.phone_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td class="alignright">${this.listOfBooking[0].passenger.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Age</td>
                                                    <td class="alignright">${this.listOfBooking[0].passenger.age_group}</td>
                                                </tr>
                                            </tbody></table>
                                        </td>
                                    </tr><br>  
                                    <tr>
                                      <td style="padding-top:5%"><b>Safari Details</b></td>
                                     </tr>
                                     <tr>
                                        <td>
                                            <table class="invoice-items" cellpadding="0" cellspacing="0">
                                                <tbody><tr>
                                                    <td>From</td>
                                                    <td class="alignright">${this.listOfBooking[0].from}</td>
                                                </tr>
                                                <tr>
                                                    <td>To</td>
                                                    <td class="alignright">${this.listOfBooking[0].to}</td>
                                                </tr>
                                                <tr>
                                                    <td>Bus</td>
                                                    <td class="alignright">${this.listOfBooking[0].trip.vehicle.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Reg No</td>
                                                    <td class="alignright">${this.listOfBooking[0].trip.vehicle.registration_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Class</td>
                                                    <td class="alignright">${this.listOfBooking[0].trip.vehicle.fleet_type}</td>
                                                </tr>
                                                <tr>
                                                    <td>Seat</td>
                                                    <td class="alignright">${this.listOfBooking[0].seat_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Baggage</td>
                                                    <td class="alignright">No</td>
                                                </tr>
                                                <tr>
                                                <td colspan="2" style="text-align: center; padding-top:20px" >
                                                  <div id="qrcode"  style="height: 130px; width: 190px;padding-left:20px"></div>
                                                  \x3Cscript type="text/javascript">
                                                  var qrcode = new QRCode("qrcode",
                                                  "${this.ref_no}");
                                                  
                                                  \x3C/script>
                                                </td>
                                              </tr>
                                            </tbody></table>
                                        </td>
                                    </tr><br>  
                                    
                                </tbody></table>
                            </td>
                    
                        </tr>
                        <tr>
                            <td class="content-block">
                                
                            </td>
                        </tr>
                        <tr>
                            <td class="content-block">
                              https://booking.mysafari.co.tz/
                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
        </tbody></table>
        <div class="footer">
            <table width="100%">
                <tbody><tr>
                  <td class="aligncenter content-block">Questions? Email <a href="mailto:"> info@hashtech.co.tz</a></td>
                </tr>
            </tbody></table>
        </div></div>
</td>
<td></td>
</tr>
</tbody></table>
</body>
</html>
`);
      printWindow.document.close();
      printWindow.print();
    },
    bookAgain() {
      localStorage.clear();
      // this.$router.push({ name: 'PaymentStatus'})
      this.$router.push({ name: "Home" });
    },
    printTicket() {},
    getPaymentModes() {
      axios
        .get("mobile-money-channels")
        .then((response) => {
          this.listOfPaymentModes = response.data;
        })
        .finally(() => {
          setTimeout(
            function () {
              this.isLoading = false;
            }.bind(this),
            20
          );
        });
    },
    selectPaymentMode(partnerName) {
      this.form.provider = partnerName;
      this.form.phone_number = this.listOfBooking[0].passenger.phone_number;
      this.form.booking_number = this.listOfBooking[0].booking_number;
    },
    startHttpRequest() {
      this.timer = setInterval(() => {
        this.sendHttpRequest();
      }, 30000); // 30000 milliseconds = 30 seconds
    },
    stopHttpRequest() {
      clearInterval(this.timer);
    },
    sendHttpRequest() {
      // Make your HTTP request using a library like Axios

      // Payment-status-called
      if (this.paymentStatus[0]) {
        axios
          .post("passenger/checkPaymentStatus", this.paymentStatus[0])
          .then((response) => {
            console.log("the response");
            console.log(response);

            //setting the ref_no::
            if (response.data.bookings && response.data.bookings.length > 0) {
              const bookingNumber = response.data.bookings[0].booking_number;
              this.ref_no = bookingNumber;
            }

            localStorage.setItem(
              "checkout_success",
              "Dear customer, your payment is successfully completed"
            );
            // Check if the desired response is obtained

            // response.data.success == true
            if (response.data.message == "Payment Incomplete") {
              this.labbelText = "Payment - Failed";
              this.current_status = "failure";

              this.pending = false;
              this.verified = false;
              this.printable = false;
            } else if (response.data.success == true) {
              this.stopHttpRequest();

              this.current_status = "success";
              this.labbelText = "Payment - Successful";
              // this.printable = true;
              this.movement = "true";

              //show verified the show printable::
              this.pending = false;
              this.verified = true;

              setTimeout(() => {
                this.verified = false;
                this.printable = true;
              }, 4000);
            } else {
              this.current_status = "pending";

              this.pending = true;
              this.verified = false;
              this.printable = false;

              // Set a timeout to change the status to 'failure' after 3 minutes
              setTimeout(() => {
                this.current_status = "failure";
                this.printable = false;
              }, 3 * 60 * 1000); // 3 minutes in milliseconds
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
      }
    },
  },
  computed: {},
  mounted() {
    // modify the truck key frame here
    this.stopHttpRequest();
    // Add animation styles for the truck
    const style = document.createElement("style");
    style.innerHTML = `
    :root {
  --loaderSize: 75px;
  --radThickness: calc(var(--loaderSize) / 10);
  --spinSpeed: 750ms;
  --backgroundColor: #eee;
  --forgroundColor: #ccc;
  --masksColor: #fff;
  --middleCircleSize: 68%;
}

/* Mixins */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rspin {
  to {
    transform: rotate(-360deg);
  }
}

/* Animations */
.spin {
  animation: spin var(--spinSpeed) infinite linear;
}

.rspin {
  animation: rspin calc(var(--spinSpeed) * 3) infinite linear;
}

/* Loader styles */
.loader {
  background-color: var(--backgroundColor);
  border-radius: 100%;
  position: relative;
  height: var(--loaderSize);
  width: var(--loaderSize);
  overflow: hidden;
}

.loader .c {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: calc(-1 * var(--middleCircleSize) / 2) 0 0 calc(-1 * var(--middleCircleSize) / 2);
  width: var(--middleCircleSize);
  height: var(--middleCircleSize);
  background-color: var(--masksColor);
  border-radius: 100%;
  z-index: 3;
}

.loader .d {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loader .d .e {
  --thisWidth: calc(var(--loaderSize) * 0.2);
  position: absolute;
  top: 1%;
  left: 50%;
  margin: 0 0 0 calc(-1 * var(--thisWidth) / 2);
  height: 14px;
  width: var(--thisWidth);
  transform: rotate(10deg) skew(20deg);
  border-radius: 3px;
  background: #999;
}

.loader .r {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: -1px;
  bottom: -1px;
  margin-left: calc(-1 * var(--radThickness) / 2);
  background-color: var(--masksColor);
  width: var(--radThickness);
}

.loader .r1 {
  transform: rotate(0deg);
}

.loader .r2 {
  transform: rotate(45deg);
}

.loader .r3 {
  transform: rotate(90deg);
}

.loader .r4 {
  transform: rotate(135deg);
}
  `;

    // Append the style element to the document's head
    document.head.appendChild(style);

    // /////////////////////////
    this.pending = true;
    this.verified = false;
    this.current_status = "pending";

    this.labbelText = "Payment -Pending";
    document.title = `Payment Verification`;

    this.getPaymentModes();
    this.startHttpRequest();
    if (localStorage.getItem("bookings")) {
      this.listOfBooking = JSON.parse(localStorage.getItem("bookings"));

      // attaining the refference data::
      this.dataUrl = this.listOfBooking[0].booking_number;

      // Log the booking_number to the console

      let newPaymentStatus = {
        payment_status: [],
      };
      this.listOfBooking.forEach((bookingObj, index) => {
        let newBooking = {
          booking_number: bookingObj.booking_number,
        };
        newPaymentStatus.payment_status.push(newBooking);
      });
      this.paymentStatus.push(newPaymentStatus);
    }
  },
};
</script>

<style scoped>
.heavy-font {
  font-family: mark_pro;
}

.bold-font {
  font-family: mark_bold;
  font-size: 20px;
}

.light-font {
  font-family: mark_light;
}

hr {
  border: none;
  border-top: 1px dotted #000;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 99%;
}

.img-payment-mode {
  max-height: 25px;
}

tr {
  line-height: 10px;
  min-height: 10px;
  height: 10px;
}

tr {
  line-height: 10px;
  min-height: 10px;
  height: 10px;
}

#hero {
  position: relative;
  height: 250px;
}

@media (max-width: 767px) {
  .intro_title {
    padding-bottom: 0rem !important;
  }
}

@media (max-width: 767px) {
  .intro_title {
    padding-bottom: 0rem !important;
  }

  a.btn_1,
  .btn_1 {
    width: 100%;
    padding: 10px 100px !important;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  #seat-map {
    border: 0px solid #eeeeee;
  }

  div.seatCharts-cell {
    width: 20% !important;
  }
}

/* Inline #6 | file:///C:/Users/Qali/Downloads/my-safari/my-safari/order-confirm.html */

h4 {
  color: #ffd428;
  font-size: 18px !important;
}

h4 small {
  color: #fff !important;
  font-size: 14px;
}

/* Element | file:///C:/Users/Qali/Downloads/my-safari/my-safari/order-confirm.html */

.box {
  background-color: #171a24;
  padding: 20px;
}

/* Inline #6 | file:///C:/Users/Qali/Downloads/my-safari/my-safari/order-confirm.html */

.box .content {
  background: white;
  padding: 10px;
}

.box .content td {
  font-size: 14px;
  font-weight: bold;
}

.box .content h5 {
  font-size: 14px !important;
  color: #e3a205;
}

.hero-background-image {
  background-image: var(--background-image);
}

/* for the issue of status */
@import url("https://fonts.googleapis.com/css?family=Varela+Round");

#status {
  margin-bottom: 1em;
}

.status {
  background: #fff;
  margin-bottom: 0.25em;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.status {
  position: relative;
  height: 1em;
  line-height: 1em;
  padding: 0.5em;
  padding-left: 2em;
  transition: color 500ms;
}

.status:before,
.status:after {
  content: "";
  display: inline-block;
  position: absolute;
  transition: all 500ms;
}

.status.-pending {
  color: #666;
}

.status.-pending:before,
.status.-pending:after {
  background: #888;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.status.-pending:before {
  width: 0.25em;
  height: 0.25em;
  top: 0.5em;
  left: 0.875em;
  border-radius: 0.125em;
  transform-origin: 50% 0.5em;
}

.status.-pending:after {
  width: 0.25em;
  height: 0.25em;
  top: 1.25em;
  left: 0.875em;
  border-radius: 0.125em;
  transform-origin: 50% -0.25em;
}

.status.-success {
  color: #368;
}

.status.-success:before,
.status.-success:after {
  background: #6cf;
  border-radius: 0.125em;
}

.status.-success:before {
  width: 0.25em;
  height: 1em;
  top: 0.5em;
  left: 0.875em;
  transform-origin: 50% 0.875em;
  transform: translate(-0.177em, -0.11em) rotate(0.125turn);
}

.status.-success:after {
  width: 0.25em;
  height: 0.5em;
  top: 1em;
  left: 0.875em;
  transform-origin: 50% 0.375em;
  transform: translate(-0.177em, -0.11em) rotate(-0.125turn);
}

.status.-failure {
  color: #802;
}

.status.-failure:before,
.status.-failure:after {
  background: #f04;
  border-radius: 0.125em;
}

.status.-failure:before {
  width: 0.25em;
  height: 1em;
  top: 0.5em;
  left: 0.875em;
  transform: rotate(0.125turn);
}

.status.-failure:after {
  width: 0.25em;
  height: 1em;
  top: 0.5em;
  left: 0.875em;
  transform: rotate(-0.125turn);
}

/* code for the ticket */
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Ubuntu", sans-serif;
  background-color: #3f32e5;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1c1c1c;
  display: flex;
  justify-content: center;
}

.ticket-system {
  max-width: 385px;
}

.ticket-system .top {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ticket-system .top .title {
  font-weight: normal;
  font-size: 1.6em;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 50px;
  color: #fff;
}

.ticket-system .top .printer {
  width: 90%;
  height: 20px;
  border: 5px solid #fff;
  border-radius: 10px;
  box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.2);
}

.ticket-system .receipts-wrapper {
  overflow: hidden;
  margin-top: -10px;
  padding-bottom: 10px;
}

.ticket-system .receipts {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateY(-510px);
  animation-duration: 2.5s;
  animation-delay: 500ms;
  animation-name: print;
  animation-fill-mode: forwards;
}

.ticket-system .receipts .receipt {
  padding: 25px 30px;
  text-align: left;
  min-height: 200px;
  width: 88%;
  background-color: #fff;
  border-radius: 10px 10px 20px 20px;
  box-shadow: 1px 3px 8px 3px rgba(0, 0, 0, 0.2);
}

.ticket-system .receipts .receipt .airliner-logo {
  max-width: 80px;
}

.ticket-system .receipts .receipt .route {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.ticket-system .receipts .receipt .route .plane-icon {
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
}

.ticket-system .receipts .receipt .route h2 {
  font-weight: 300;
  font-size: 2.2em;
  margin: 0;
}

.ticket-system .receipts .receipt .details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ticket-system .receipts .receipt .details .item {
  display: flex;
  flex-direction: column;
  min-width: 70px;
}

.ticket-system .receipts .receipt .details .item span {
  font-size: 0.8em;
  color: rgba(28, 28, 28, 0.7);
  font-weight: 500;
}

.ticket-system .receipts .receipt .details .item h3 {
  margin-top: 10px;
  margin-bottom: 25px;
}

.ticket-system .receipts .receipt.qr-code {
  height: 110px;
  min-height: unset;
  position: relative;
  border-radius: 20px 20px 10px 10px;
  display: flex;
  align-items: center;
}

/* the line */
.ticket-system .receipts .receipt.qr-code::before {
  content: "";
  background: linear-gradient(to right, #fff 50%, #fbd127 50%);
  background-size: 22px 4px, 100% 4px;
  height: 4px;
  width: 90%;
  display: block;
  left: 0;
  right: 0;
  top: -1px;
  position: absolute;
  margin: auto;
}

.ticket-system .receipts .receipt.qr-code .qr {
  width: 70px;
  height: 70px;
}

.ticket-system .receipts .receipt.qr-code .description {
  margin-left: 20px;
}

.ticket-system .receipts .receipt.qr-code .description h2 {
  margin: 0 0 5px 0;
  font-weight: 500;
}

.ticket-system .receipts .receipt.qr-code .description p {
  margin: 0;
  font-weight: 400;
}

@keyframes print {
  0% {
    transform: translateY(-510px);
  }

  35% {
    transform: translateY(-395px);
  }

  70% {
    transform: translateY(-140px);
  }

  100% {
    transform: translateY(0);
  }
}

/* tickt code ends here */

/* code for pending */

.spin-loader {
  padding-top: 50%;
}

.spin-loader1 {
  padding-bottom: 50%;
}

.main-loader {
  padding-left: 35%;
}

.hero1-background-image {
  background-image: url(../assets/images/ticket_bg1.jpeg);
  height: 12%;
}

/* tick animation  */
* {
  padding: 0;
  margin: 0;
}

.wrapper {
  height: 70vh;
  display: flex;
  padding-top: 50%;

  background-color: #fbd127;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

/* tick end */

/* print button */
button.print-button {
  width: 100px;
  height: 100px;
}

span.print-icon,
span.print-icon::before,
span.print-icon::after,
button.print-button:hover .print-icon::after {
  border: solid 4px #333;
}

span.print-icon::after {
  border-width: 2px;
}

button.print-button {
  position: relative;
  padding: 0;
  border: 0;

  border: none;
  background: transparent;
}

span.print-icon,
span.print-icon::before,
span.print-icon::after,
button.print-button:hover .print-icon::after {
  box-sizing: border-box;
  background-color: #fff;
}

span.print-icon {
  position: relative;
  display: inline-block;
  padding: 0;
  margin-top: 20%;

  width: 60%;
  height: 35%;
  background: #fff;
  border-radius: 20% 20% 0 0;
}

span.print-icon::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 12%;
  right: 12%;
  height: 110%;

  transition: height 0.2s 0.15s;
}

span.print-icon::after {
  content: "";
  position: absolute;
  top: 55%;
  left: 12%;
  right: 12%;
  height: 0%;
  background: #fff;
  background-repeat: no-repeat;
  background-size: 70% 90%;
  background-position: center;
  background-image: linear-gradient(
    to top,
    #fff 0,
    #fff 14%,
    #333 14%,
    #333 28%,
    #fff 28%,
    #fff 42%,
    #333 42%,
    #333 56%,
    #fff 56%,
    #fff 70%,
    #333 70%,
    #333 84%,
    #fff 84%,
    #fff 100%
  );

  transition: height 0.2s, border-width 0s 0.2s, width 0s 0.2s;
}

button.print-button:hover {
  cursor: pointer;
}

button.print-button:hover .print-icon::before {
  height: 0px;
  transition: height 0.2s;
}

button.print-button:hover .print-icon::after {
  height: 120%;
  transition: height 0.2s 0.15s, border-width 0s 0.16s;
}

/* the fare span */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.fare-span {
  font-family: "Bebas Neue", cursive;
  font-size: 30px;
  color: #000;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(gold, gold);
  background-size: 100% 10px;
  background-repeat: no-repeat;
  background-position: 0% 100%;
  /* Change to start at the bottom */
  transition: background-size 0.7s, background-position 0.5s ease-in-out;
  margin-bottom: 5px;
  /* Add some space below the text */
}

.fare-span:hover {
  background-size: 100% 100%;
  background-position: 0% 90%;
  /* Adjust the vertical position to move it up a bit when hovering */
  transition: background-position 0.7s, background-size 0.5s ease-in-out;
}

/* if payment failure */
HTML {
  /*using system font-stack*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 115%;
  /*~18px*/
  font-size: calc(12px + (25 - 12) * (100vw - 300px) / (1300 - 300));
  line-height: 1.5;
  box-sizing: border-box;
}

BODY {
  margin: 0;
  color: #3a3d40;
  background: rgb(252, 252, 252);
}

*,
*::before,
*::after {
  box-sizing: inherit;
  color: inherit;
}

/*Actual Style*/

/*=======================
       C-Container
=========================*/
.c-container {
  max-width: 27rem;
  margin: 1rem auto 0;
  padding: 1rem;

  margin-top: -10px;
}

/*=======================
       O-Circle
=========================*/

.o-circle {
  display: flex;
  width: 10.555rem;
  height: 10.555rem;
  justify-content: center;
  align-items: flex-start;
  border-radius: 50%;
  animation: circle-appearance 0.8s ease-in-out 1 forwards,
    set-overflow 0.1s 1.1s forwards;
}

.c-container__circle {
  margin: 0 auto 5.5rem;
}

/*=======================
    C-Circle Sign
=========================*/

.o-circle__sign {
  position: relative;
  opacity: 0;
  background: #fff;
  animation-duration: 0.8s;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.o-circle__sign::before,
.o-circle__sign::after {
  content: "";
  position: absolute;
  background: inherit;
}

.o-circle__sign::after {
  left: 100%;
  top: 0%;
  width: 500%;
  height: 95%;
  transform: translateY(4%) rotate(0deg);
  border-radius: 0;
  opacity: 0;
  animation: set-shaddow 0s 1.13s ease-in-out forwards;
  z-index: -1;
}

/*=======================
      Sign Success
=========================*/

.o-circle__sign--success {
  background: rgb(56, 176, 131);
}

.o-circle__sign--success .o-circle__sign {
  width: 1rem;
  height: 6rem;
  border-radius: 50% 50% 50% 0% / 10%;
  transform: translateX(130%) translateY(35%) rotate(45deg) scale(0.11);
  animation-name: success-sign-appearance;
}

.o-circle__sign--success .o-circle__sign::before {
  bottom: -17%;
  width: 100%;
  height: 50%;
  transform: translateX(-130%) rotate(90deg);
  border-radius: 50% 50% 50% 50% / 20%;
}

/*--shadow--*/
.o-circle__sign--success .o-circle__sign::after {
  background: rgb(40, 128, 96);
}

/*=======================
      Sign Failure
=========================*/

.o-circle__sign--failure {
  background: rgb(236, 78, 75);
}

.o-circle__sign--failure .o-circle__sign {
  width: 1rem;
  height: 7rem;
  transform: translateY(25%) rotate(45deg) scale(0.1);
  border-radius: 50% 50% 50% 50% / 10%;
  animation-name: failure-sign-appearance;
}

.o-circle__sign--failure .o-circle__sign::before {
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%) rotate(90deg);
  border-radius: inherit;
}

/*--shadow--*/
.o-circle__sign--failure .o-circle__sign::after {
  background: rgba(175, 57, 55, 0.8);
}

/*-----------------------
      @Keyframes
--------------------------*/

/*CIRCLE*/
@keyframes circle-appearance {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  60% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

/*SIGN*/
@keyframes failure-sign-appearance {
  50% {
    opacity: 1;
    transform: translateY(25%) rotate(45deg) scale(1.7);
  }

  100% {
    opacity: 1;
    transform: translateY(25%) rotate(45deg) scale(1);
  }
}

@keyframes success-sign-appearance {
  50% {
    opacity: 1;
    transform: translateX(130%) translateY(35%) rotate(45deg) scale(1.7);
  }

  100% {
    opacity: 1;
    transform: translateX(130%) translateY(35%) rotate(45deg) scale(1);
  }
}

@keyframes set-shaddow {
  to {
    opacity: 1;
  }
}

@keyframes set-overflow {
  to {
    overflow: hidden;
  }
}

/*+++++++++++++++++++
    @Media Queries
+++++++++++++++++++++*/

@media screen and (min-width: 1300px) {
  HTML {
    font-size: 1.5625em;
  }

  /* 25 / 16 = 1.5625 */
}

/* handling the contents in general */
.failure-button {
  zoom: 0.5;
}

/* handling money on small devices */
@media screen and (max-width: 422px) {
  .money {
    font-size: 15px;
  }

  /* Hide the "above_screens" on smaller screens */
  .above_screens {
    display: none;
  }

  /* Show the "smaller_screens" on smaller screens */
  .smaller_screens {
    display: block;
  }
}

/* For screens larger than 422px, reverse the display */
@media screen and (min-width: 423px) {
  .above_screens {
    display: contents;
  }

  .smaller_screens {
    display: none;
  }
}
</style>
