<template>
  <!-- <header class="header-area">
    <div class="header-top-bar padding-right-100px padding-left-100px">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="header-top-content">
              <div class="header-left">
                <ul class="list-items">
                  <li>
                    <a href="#"
                      ><i class="la la-phone mr-1"></i>(255) 656 121 885</a
                    >
                  </li>
                  <li>
                    <a href="#"
                      ><i class="la la-envelope mr-1"></i
                      >support@hashtek.co.tz</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="header-top-content">
              <div class="header-right d-flex align-items-center justify-content-end" >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="topnav" class="header-menu-wrapper padding-right-100px padding-left-100px">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <div class="menu-wrapper">
              <a href="#" class="down-button"
                ><i class="la la-angle-down"></i ></a>
              <div class="logo">
                <router-link :to="{ name : 'Home' }" ><img src="@/assets/logo/logo.png" alt="logo" /></router-link>
                <div class="menu-toggler">
                  <i class="la la-bars"></i>
                  <i class="la la-times"></i>
                </div>
              </div>
              <div class="main-menu-content">
                <nav>
                  <ul>
                    <li>
                      <router-link :to="{ name : 'Search' }" >Search</router-link>
                    </li>
                    <li>
                      <router-link to="" >Recover Ticket </router-link>
                    </li>
                    <li>
                      <router-link to="" >Contact Us</router-link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header> -->



  <header id="topnav">

    <div class="container">
      <div class="row">
        <div class="col-3">
          <div id="logo_home">
            <router-link :to="{ name: 'Home' }">
              <!-- change here -->
              <img
                src="@/assets/logo/logo.png"
                width="130"
                height="40"
                alt="my safari"
              />
            </router-link>
          </div>
        </div>
        <nav class="col-9">
          <a
            class="cmn-toggle-switch cmn-toggle-switch__htx open_close"
            href="javascript:void(0);"
            ><span>Menu mobile</span></a
          >
          <div class="main-menu">
            <div id="header_menu">
              <router-link :to="{ name: 'Home' }">
                <img
                  src="@/assets/logo/logo.png"
                  width="100"
                height="70"
                  alt="my safari"
                />
              </router-link>
            </div>
            <a href="#" class="open_close" id="close_in"
              ><i class="icon_set_1_icon-77"></i
            ></a>
            <ul>
              <!-- <li class="submenu">
              <a href="javascript:void(0);" class="show-submenu">Recover Ticket</a>
            </li>
            <li class="submenu">
              <a href="javascript:void(0);" class="show-submenu">Get Help</a>

            </li> -->
              <!-- <li class="submenu">
                <a href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-instagram"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                    />
                  </svg>
                </a>
              </li>

              <li class="submenu">
                <a href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-globe2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M15.65 7A7.5 7.5 0 0 0 8 0a7.5 7.5 0 0 0-7.35 7H0v2h.65A7.5 7.5 0 0 0 8 16a7.5 7.5 0 0 0 7.35-7H16V7h-.35zM13.5 7h-2V5.67c0-.368-.224-.717-.556-.857L9 4.018V3h1.5a.5.5 0 0 1 0 1H11v1.018a2.5 2.5 0 1 0 1 0V4h.5a.5.5 0 0 1 0 1h-.5v1.146l1.444.794A1.496 1.496 0 0 0 13.5 7zM8 5.5V7H6V5.5a.5.5 0 0 1 1 0zm0-4a6.5 6.5 0 0 1 5.37 10H8V1.5zm-2 0V4H3.63A6.5 6.5 0 0 1 6 1.5zM1.5 7h2V8H1.5a.5.5 0 0 1 0-1zm0 2h2v1h-2a.5.5 0 1 1 0-1zm2.5 2H3v1h1a.5.5 0 1 1 0 1H3v1.5a6.5 6.5 0 0 1 5.37-10H8V1.5A7.5 7.5 0 0 0 1.5 7z"
                    />
                  </svg>
                </a>
              </li> -->
              <!-- <li class="submenu">
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                </svg>
              </a>
            </li> -->
              <!-- <li class="submenu">
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
              </svg>
              </a>
            </li> -->
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>
<script>
import { ref } from 'vue';
export default {
  data() {
    return {
      pageRefreshed: false, 
      token: false,
    };
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("scroll", function() {
        var navbar = document.getElementById("topnav");
        var nav_classes = navbar.classList;
        if (document.documentElement.scrollTop >= 5) {
          if (nav_classes.contains("sticky") === false) {
            nav_classes.toggle("sticky");
          }
        } else {
          if (nav_classes.contains("sticky") === true) {
            nav_classes.toggle("sticky");
          }
        }
      });
      // window.addEventListener("scroll", function(){
      //   var navbar = document.getElementById("smallNav");
      //   var nav_classes = navbar.classList;
      //   if(document.documentElement.scrollTop >= 5) {
      //     if (nav_classes.contains("d-none") === false) {
      //       nav_classes.toggle("d-none");
      //     }
      //   }
      //   else {
      //     if (nav_classes.contains("d-none") === true) {
      //       nav_classes.toggle("d-none");
      //     }
      //   }
      // })
    });
  },
  methods: {
    getToken() {
      return (this.token = localStorage.getItem("user_data"));
    },
  },
  created() {
 
    this.getToken();
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("scroll", function() {
        var navbar = document.getElementById("topnav");
        var nav_classes = navbar.classList;
        if (document.documentElement.scrollTop >= 5) {
          if (nav_classes.contains("sticky") === false) {
            nav_classes.toggle("sticky");
          }
        } else {
          if (nav_classes.contains("sticky") === true) {
            nav_classes.toggle("sticky");
          }
        }
      });
    });
    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
}
};



</script>
<style scoped>
.text-blue {
  color: #01406a;
}
.nav-bg {
  background-color: #01406a;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.dropdown-item:hover {
  color: #8ec73a !important;
  background-color: #01406a !important;
}
.btn-light {
  color: #828a93 !important;
}
.nav-link:hover {
  color: #8ec73a;
}
.router-link-exact-active {
  color: #8ec73a !important;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

#topnav .navigation-menu > li > a {
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 0px;
}
.auth_button {
  min-width: 150px;
  max-width: 150px;
}
.hidden-mobile {
  /* background-image: url(../assets/img/nav.jpg); */
  background-color: #e8effd;
}
</style>
