<template>
    <Navbar  style="visibility: hidden;"/>

  <main>
      <div>

          <div class="main_title">
              <!-- <h2 class="mb-2 text-capitalize">Order Confirm and Payment</h2> -->
          </div>

          <div class="row justify-content-center">

              <div class="col-md-4  pe-4 wow zoomIn " data-wow-delay="0.2s" v-for="(booking, index) in listOfBooking"
                  :key="index">
                  <div>
                      <main class="ticket-system">

                          <!-- end of pending -->
                          <div class="ticket">

                              <div class="receipts-wrapper">
                                  <div class="receipts">
                                      <div class="receipt">

                                          <h2 class=" heavy-font" style=" font-size: 19px;">{{ booking.trip.vehicle.name
                                          }}</h2>
                                          <!-- <img src="@/assets/logo/logo.png" style="width: 100%;height: 40px;object-fit: contain;" /> -->

                                          <div class="route">
                                              <h2 class=" heavy-font">{{ booking.from_code }}</h2>

                                              <img src="@/assets/images/bus_logo.png"
                                                  style="width: 100%;height: 65px;object-fit: contain;" />

                                              <h2 class=" heavy-font">{{ booking.to_code }}</h2>
                                          </div>
                                          <div class="details">

                                              <div class="item">
                                                  <span class="bold-font">Bus Reg No</span>
                                                  <h3 class="light-font" style=" font-size: 15px;">{{
                                                      booking.trip.vehicle.registration_number }}
                                                  </h3>
                                              </div>
                                              <div class="item" style="padding-left: 8%;">
                                                  <span class="bold-font">Reporting Time</span>
                                                  <h3 class="light-font" style=" font-size: 15px;">{{
                                                      booking.trip.reporting_time }}</h3>
                                              </div>

                                              <div class="item">
                                                  <span class="bold-font">Departure Time</span>
                                                  <h3 class="light-font" style=" font-size: 15px;">{{
                                                      booking.trip.departing_time }}</h3>
                                              </div>

                                              <div class="item">
                                                  <span class="bold-font">Passenger</span>
                                                  <h3 class="light-font" style=" font-size: 15px;">{{
                                                      booking.passenger.name }}</h3>
                                              </div>
                                              <div class="item">
                                                  <span class="bold-font">Phone No.</span>
                                                  <h3 class="light-font" style=" font-size: 15px;">{{
                                                      booking.passenger.phone_number }}</h3>
                                              </div>

                                              <div class="item">
                                                  <span class="bold-font">Email</span>
                                                  <h3 class="light-font" style=" font-size: 15px;">{{
                                                      booking.passenger.email }}</h3>
                                              </div>

                                              <div class="item">
                                                  <span class="bold-font">Age</span>
                                                  <h3 class="light-font" style=" font-size: 15px;">{{
                                                      booking.passenger.age_group }}</h3>
                                              </div>

                                              <div class="item">
                                                  <span class="bold-font">Seat</span>
                                                  <h3 class="light-font" style=" font-size: 15px;">{{ booking.seat_number
                                                  }}</h3>
                                              </div>
                                              <div class="item">
                                                  <span class="bold-font">Baggage</span>
                                                  <h3 class="light-font" style=" font-size: 15px;">No</h3>
                                              </div>

                                          </div>

                                          <h3 class="bold-font" style="padding-left:10%;">Total Fare: Tsh {{
                                              booking.trip.fare }}</h3>



                                          <div class="two-column-container">
                                              <div class="left-column">
                                                  <vue-qrcode :value="dataUrl" @change="onDataUrlChange" />
                                              </div>
                                              <div class="right-column">
                                                  <div class="description">
                                                      <h2 class="bold_font" style="font-size: 20px;">REF:</h2>
                                                      <p>{{ booking.booking_number }}</p>
                                                  </div>
                                              </div>
                                          </div>

                                      </div>
                                      <br>

                                  </div>
                              </div>
                          </div>
                      </main>
                      <!-- payment ends here -->
                      <p>Questions? Email <a href="mailto:"> info@hashtech.co.tz</a></p>
                  </div>
              </div>
          </div>

      </div>
      <!-- End container -->
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Loading from "@/views/custom/Loading.vue";
import { jsPDF } from "jspdf";
import VueQrcode from 'vue-qrcode'

export default {
  components: {Navbar, Loading, VueQrcode },
  data() {
      return {
          labbelText: '',
          dataUrl: '',
          pending: "",
          printable: "",
          ref_no: "",
          isLoading: true,
          listOfBooking: [],
          listOfPaymentModes: [],
          checkoutSuccess: localStorage.getItem("checkout_success"),
          transctionNumber: localStorage.getItem("transactionId"),
          form: {
              provider: "",
              phone_number: "",
              booking_number: "",
          },
          paymentStatus: [],
          error: "",
      };
  },
  methods: {
      printPage() {

          
  // Create a flag to track whether the print dialog is open
  let printDialogOpen = true;

  // Create a function to be called when the user closes the print dialog
  const onPrintDialogClose = () => {
      if (!printDialogOpen) {
          // The print dialog is already closed, so we don't need to do anything
          return;
      }


      printDialogOpen = false;
      sessionStorage.setItem('bookingSuccessful', 'true');
      this.$router.push({ name: 'Home' });
  };

  // Trigger the print dialog
  window.print();

  // Add an event listener to detect when the window gains focus
  window.addEventListener('focus', onPrintDialogClose);

  // Check for changes in window's focus to detect when the print dialog is closed
  const focusInterval = setInterval(() => {
      if (document.hasFocus()) {
          // The print dialog is closed
          clearInterval(focusInterval);

          // Call the onPrintDialogClose function to perform the desired actions
          onPrintDialogClose();

          // Remove the focus event listener
          window.removeEventListener('focus', onPrintDialogClose);
      }
  }, 1000); // Check every 1 second
},
      pdfConvert() {
          this.$router.push({ name: "downloadPdf" });

          //     	var doc = new jsPDF();

          // doc.text("Hello world!", 10, 10);
          // doc.save("a4.pdf");

          window.print()

      },
      getPaymentModes() {
          axios
              .get("mobile-money-channels")
              .then((response) => {
                  this.listOfPaymentModes = response.data;
              })
              .finally(() => {
                  setTimeout(
                      function () {
                          this.isLoading = false;
                      }.bind(this),
                      20
                  );
              });
      },
      selectPaymentMode(partnerName) {
          this.form.provider = partnerName;
          this.form.phone_number = this.listOfBooking[0].passenger.phone_number;
          this.form.booking_number = this.listOfBooking[0].booking_number;
      },
      startHttpRequest() {
          this.timer = setInterval(() => {
              this.sendHttpRequest();
          }, 30000); // 30000 milliseconds = 30 seconds
      },
      stopHttpRequest() {
          clearInterval(this.timer);
      },
      sendHttpRequest() {
          
          

      },
  },
  computed: {},
  mounted() {
      this.dataUrl = this.$route.params.dataUrl;
   
      // Trigger the print function

      setTimeout(() => {
          // Trigger the print function
          this.printPage();

      }, 1000);


      // Create an event listener for the afterprint event
      // const afterPrintHandler = () => {

      //     sessionStorage.setItem('bookingSuccessful', 'true');
      //     // Redirect to the desired URL ("/" in this case) after the print dialog is closed
      //     // this.$router.push({ name: 'Home' });

      //     setTimeout(() => {
      //     // Trigger the print function
      //    this.$router.push({ name: 'Home' });
      // }, 1000);

      //     // Remove the event listener to prevent it from firing again
      //     window.removeEventListener('afterprint', afterPrintHandler);
      // };

      // Add the event listener for afterprint
      // window.addEventListener('afterprint', afterPrintHandler);



      // this.$router.push({ name: "Home" });

      
      // modify the truck key frame here
      this.stopHttpRequest();
      // Add animation styles for the truck
      const style = document.createElement("style");
      style.innerHTML = `
    :root {
  --loaderSize: 75px;
  --radThickness: calc(var(--loaderSize) / 10);
  --spinSpeed: 750ms;
  --backgroundColor: #eee;
  --forgroundColor: #ccc;
  --masksColor: #fff;
  --middleCircleSize: 68%;
}

/* Mixins */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rspin {
  to {
    transform: rotate(-360deg);
  }
}

/* Animations */
.spin {
  animation: spin var(--spinSpeed) infinite linear;
}

.rspin {
  animation: rspin calc(var(--spinSpeed) * 3) infinite linear;
}

/* Loader styles */
.loader {
  background-color: var(--backgroundColor);
  border-radius: 100%;
  position: relative;
  height: var(--loaderSize);
  width: var(--loaderSize);
  overflow: hidden;
}

.loader .c {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: calc(-1 * var(--middleCircleSize) / 2) 0 0 calc(-1 * var(--middleCircleSize) / 2);
  width: var(--middleCircleSize);
  height: var(--middleCircleSize);
  background-color: var(--masksColor);
  border-radius: 100%;
  z-index: 3;
}

.loader .d {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loader .d .e {
  --thisWidth: calc(var(--loaderSize) * 0.2);
  position: absolute;
  top: 1%;
  left: 50%;
  margin: 0 0 0 calc(-1 * var(--thisWidth) / 2);
  height: 14px;
  width: var(--thisWidth);
  transform: rotate(10deg) skew(20deg);
  border-radius: 3px;
  background: #999;
}

.loader .r {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: -1px;
  bottom: -1px;
  margin-left: calc(-1 * var(--radThickness) / 2);
  background-color: var(--masksColor);
  width: var(--radThickness);
}

.loader .r1 {
  transform: rotate(0deg);
}

.loader .r2 {
  transform: rotate(45deg);
}

.loader .r3 {
  transform: rotate(90deg);
}

.loader .r4 {
  transform: rotate(135deg);
}
  `;

      // Append the style element to the document's head
      document.head.appendChild(style);


      this.getPaymentModes();
      this.startHttpRequest();
      if (localStorage.getItem("bookings")) {
          this.listOfBooking = JSON.parse(localStorage.getItem("bookings"));
          
          
          let newPaymentStatus = {
              payment_status: [],
          };
          this.listOfBooking.forEach((bookingObj, index) => {
              let newBooking = {
                  booking_number: bookingObj.booking_number,
              };
              newPaymentStatus.payment_status.push(newBooking);
          });

          
          
      }
  },
};
</script>

<style scoped>
.heavy-font {
  font-family: mark_pro;
}

.bold-font {
  font-family: mark_bold;
  font-size: 20px;
}

.light-font {
  font-family: mark_light;
}



hr {
  border: none;
  border-top: 1px dotted #000;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 99%;
}

.img-payment-mode {
  max-height: 25px;
}

tr {
  line-height: 10px;
  min-height: 10px;
  height: 10px;
}

tr {
  line-height: 10px;
  min-height: 10px;
  height: 10px;
}

#hero {
  position: relative;
  height: 250px;
}

@media (max-width: 767px) {
  .intro_title {
      padding-bottom: 0rem !important;
  }
}

@media (max-width: 767px) {
  .intro_title {
      padding-bottom: 0rem !important;
  }

  a.btn_1,
  .btn_1 {
      width: 100%;
      padding: 10px 100px !important;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
  }

  #seat-map {
      border: 0px solid #eeeeee;
  }

  div.seatCharts-cell {
      width: 20% !important;
  }
}

/* Inline #6 | file:///C:/Users/Qali/Downloads/my-safari/my-safari/order-confirm.html */

h4 {
  color: #ffd428;
  font-size: 18px !important;
}

h4 small {
  color: #fff !important;
  font-size: 14px;
}

/* Element | file:///C:/Users/Qali/Downloads/my-safari/my-safari/order-confirm.html */

.box {
  background-color: #171a24;
  padding: 20px;
}

/* Inline #6 | file:///C:/Users/Qali/Downloads/my-safari/my-safari/order-confirm.html */

.box .content {
  background: white;
  padding: 10px;
}

.box .content td {
  font-size: 14px;
  font-weight: bold;
}

.box .content h5 {
  font-size: 14px !important;
  color: #e3a205;
}

.hero-background-image {
background-image:  var(--background-image);
}

/* code for the ticket */
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Ubuntu", sans-serif;
  background-color: #3f32e5;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1c1c1c;
  display: flex;
  justify-content: center;
}

.ticket-system {
  max-width: 385px;
}

.ticket-system .top {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ticket-system .top .title {
  font-weight: normal;
  font-size: 1.6em;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 50px;
  color: #fff;
}

.ticket-system .top .printer {
  width: 90%;
  height: 20px;
  border: 5px solid #fff;
  border-radius: 10px;
  box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.2);
}

.ticket-system .receipts-wrapper {
  overflow: hidden;
  margin-top: -10px;
  padding-bottom: 10px;
}

.ticket-system .receipts {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ticket-system .receipts .receipt {
  padding: 25px 30px;
  text-align: left;
  min-height: 200px;
  width: 88%;
  background-color: #fff;
  border-radius: 10px 10px 20px 20px;
  box-shadow: 1px 3px 8px 3px rgba(0, 0, 0, 0.2);
}

.ticket-system .receipts .receipt .airliner-logo {
  max-width: 80px;
}

.ticket-system .receipts .receipt .route {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.ticket-system .receipts .receipt .route .plane-icon {
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
}

.ticket-system .receipts .receipt .route h2 {
  font-weight: 300;
  font-size: 2.2em;
  margin: 0;
}

.ticket-system .receipts .receipt .details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ticket-system .receipts .receipt .details .item {
  display: flex;
  flex-direction: column;
  min-width: 70px;
}

.ticket-system .receipts .receipt .details .item span {
  font-size: 0.8em;
  color: rgba(28, 28, 28, 0.7);
  font-weight: 500;
}

.ticket-system .receipts .receipt .details .item h3 {
  margin-top: 10px;
  margin-bottom: 25px;
}

.ticket-system .receipts .receipt.qr-code {
  height: 110px;
  min-height: unset;
  position: relative;
  border-radius: 20px 20px 10px 10px;
  display: flex;
  align-items: center;
}

/* the line */
.ticket-system .receipts .receipt.qr-code::before {
  content: "";
  background: linear-gradient(to right, #fff 50%, #FBD127 50%);
  background-size: 22px 4px, 100% 4px;
  height: 4px;
  width: 90%;
  display: block;
  left: 0;
  right: 0;
  top: -1px;
  position: absolute;
  margin: auto;
}

.ticket-system .receipts .receipt.qr-code .qr {
  width: 70px;
  height: 70px;
}

.ticket-system .receipts .receipt.qr-code .description {
  margin-left: 20px;
}

.ticket-system .receipts .receipt.qr-code .description h2 {
  margin: 0 0 5px 0;
  font-weight: 500;
}

.ticket-system .receipts .receipt.qr-code .description p {
  margin: 0;
  font-weight: 400;
}

/* column */
.two-column-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-column {
  width: 50%;
  /* Adjust the width as needed */
  padding: 20px;
  /* Add padding for spacing */
}

.right-column {
  width: 50%;
  /* Adjust the width as needed */
  padding: 20px;
  /* Add padding for spacing */
}

/* Add any additional styling as needed */

/* pdf button */
.print-link {
  text-decoration: none;
  /* Remove underline */
  color: #007BFF;
  /* Set the link color */
  cursor: pointer;
  /* Change cursor on hover */
}

.print-link {
  text-decoration: underline;
  /* Add underline on hover */
}

@media print {
  /* Hide the "Download PDF" link when printing */
  .pdf {
    display: none;
  }
}

</style>
