import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/bootstrap.min.css'
import './assets/css/shistyle.css'
import './assets/css/vendors.css'
import './assets/css/commons.css'
import './assets/seats/css/jquery.seat-charts.css'
import './assets/seats/css/style.css'
import MenuIcon from "vue-material-design-icons/Menu.vue"
import Pagination from 'v-pagination-3';
import VueHtml2Canvas from 'vue-html2canvas';
import GSignInButton from 'vue-google-signin-button'
import { createHead } from '@vueuse/head'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import axiosApi from 'axios';


// const token = '19527585|c4Nh2TKqVe5UizgKBRTN8RBb3H60Jfx8hrPdZe4P';

//fetching the token::
axiosApi.post('https://mysafari.co.tz/api/verify-otp', {
  phone_number: '255715000010',
  otp: '8623'
})
.then(response => {
  if (response.data.success) {
    const token = response.data.user.access_token;

    
        const axios = axiosApi.create({
            baseURL:`https://mysafari.co.tz/api`,
            // baseURL:`http://127.0.0.1:8000/api`,
        });
        const head = createHead()
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        //Use the window object to make it available globally.
        window.axios = axios;

        createApp(App).use(router).use(head).use(VueHtml2Canvas).use(GSignInButton).component("menu-icon", MenuIcon).component('pagination', Pagination).mount('#app')

    // Perform other actions or make additional API calls with the token.
  } else {
    // Handle the case where OTP verification failed.
    console.error('OTP verification failed.');
  }
})
.catch(error => {
  // Handle any errors that occurred during the request.
  console.error('An error occurred:', error);
});
//logic ends



// ////////////// for testing  ///////////////





