<template>
  <Navbar />
  <section
    id="hero"
    class="background-image hero-background-image"
    data-background="url(assets/rev-slider-files/assets/logo22_mysafari.jpg)"
  >
    <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
      <div class="intro_title">
        <h3
          class="mt-0 text-capitalize"
          style="font-weight: 700; font-size: 3rem"
        ></h3>
        <br />
      </div>
    </div>
  </section>
  <main>
    <div class="container margin_60">
      <div class="row justify-content-center" v-if="!isLoading">
        <div class="col-md-4 col-sm-12">
          <h3 class="mb-4 text-capitalize complete-title">Select Seat</h3>
          <div class="grid-50">
            <div id="seat-map">
              <div class="seat-space">
                <p v-if="seats == ''">
                  <b>Error in setting seat Configuration!</b>
                </p>

                <div
                  id="bus-seat-map"
                  class="seatCharts-container"
                  tabindex="0"
                  aria-activedescendant="F2"
                >
                  <div
                    class="seatCharts-row row"
                    v-for="(row, rowIndex) in seats"
                    :key="rowIndex"
                    :class="{ 'last-row': isLastRow(rowIndex) }"
                  >
                    <div
                      class="col-sm-2 col-2"
                      v-for="(seat, seatIndex) in row"
                      :key="seatIndex"
                    >
                      <template v-if="seat.space_type == 'GAP'">
                        <div class="seatCharts-cell seatCharts-space"></div>
                      </template>
                      <template v-else-if="seat.space_type == 'DRIVER'">
                        <img
                          style="margin-left: 10px"
                          src="@/assets/images/sterring.png"
                          width="30"
                          height="30"
                          alt=""
                        />
                      </template>
                      <template v-else-if="seat.space_type == 'TOILET'">
                        <img
                          style="margin-left: 10px"
                          src="@/assets/images/toilet.png"
                          width="30"
                          height="30"
                          alt=""
                        />
                      </template>
                      <template v-else-if="seat.space_type == 'DOOR'">
                        <img
                          style="margin-top: 10px"
                          src="@/assets/images/door.png"
                          width="20"
                          height="20"
                          alt=""
                        />
                      </template>
                      <template v-else>
                        <div
                          @click="seat.is_reserved || selectSeat(seat.id)"
                          role="button"
                          aria-checked="false"
                          focusable="true"
                          tabindex="-1"
                          :class="{
                            available:
                              seat.is_selected == false &&
                              seat.is_reserved == false,
                            unavailable: seat.is_reserved == true,
                            selected:
                              seat.is_selected == true &&
                              seat.space_type != 'GAP' &&
                              seat.space_type != 'DRIVER',
                          }"
                          class="seatCharts-seat seatCharts-cell economy-class btn p-3 seats"
                        >
                          {{ seat.seat_number }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-top: 20px">
              <div id="legend" class="seatCharts-legend">
                <ul class="seatCharts-legendList">
                  <li class="seatCharts-legendItem">
                    <div
                      class="seatCharts-seat seatCharts-cell available first-class"
                    ></div>
                    <span class="seatCharts-legendDescription"
                      >Available Seat</span
                    >
                  </li>
                  <li class="seatCharts-legendItem">
                    <div
                      class="seatCharts-seat seatCharts-cell selected economy-class"
                    ></div>
                    <span class="seatCharts-legendDescription"
                      >Select Seat</span
                    >
                  </li>
                  <li class="seatCharts-legendItem">
                    <div
                      class="seatCharts-seat seatCharts-cell unavailable first-class"
                    ></div>
                    <span class="seatCharts-legendDescription"
                      >Already Booked</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-7 sm-12 form-area">
          <h3 class="mb-4 text-capitalize complete-title"></h3>
          <div class="text-center alert alert-danger" v-if="error">
            {{ error }}
          </div>
          <div
            style="
              background-color: #efefef;
              padding: 50px 30px;
              border-radius: 15px;
            "
            v-if="selectedSeats.length"
          >
            <form v-for="(form, index) in selectedSeats" :key="index">
              <h3 class="text-center">Seat No: {{ form.seat_number }}</h3>
              <!-- <div class="row">
                    <hr class="mt-0 mb-2">
                </div> -->
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>First name</label>
                    <input
                      id="first_name"
                      type="text"
                      class="form-control"
                      v-model="form.first_name"
                    />
                    <input
                      v-if="first_name_error"
                      type="text"
                      class="form-control alert alert-danger"
                      :value="first_name_error"
                      style="background-color: #f8d7da; margin-top: 2%"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Last name</label>
                    <input
                      type="text"
                      id="second_name"
                      class="form-control"
                      v-model="form.last_name"
                    />
                    <input
                      v-if="second_name_error"
                      type="text"
                      class="form-control alert alert-danger"
                      :value="second_name_error"
                      style="background-color: #f8d7da; margin-top: 2%"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      id="phone_number"
                      v-model="form.phone_number"
                      class="form-control"
                    />
                    <input
                      v-if="phone_error"
                      type="text"
                      class="form-control alert alert-danger"
                      :value="phone_error"
                      style="
                        background-color: #f8d7da;
                        margin-top: 2%;
                        line-height: 40px;
                      "
                      readonly
                    />
                    <textarea
                      v-if="phone_long_error"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      style="
                        margin-top: 2%;
                        height: 80px;
                        resize: none;
                        background-color: #f8d7da;
                      "
                      :value="phone_long_error"
                      readonly
                    ></textarea>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      id="email"
                      class="form-control"
                      v-model="form.email"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="exampleInputEmail1" class="form-label"
                    >Id Type</label
                  >
                  <select
                    id="id_option"
                    v-model="form.identification_type_id"
                    class="form-control"
                  >
                    <option value="">Choose ID Type</option>
                    <option
                      :value="type.id"
                      v-for="(type, index) in listOfNationalIds"
                      :key="index"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                  <input
                    v-if="id_error"
                    type="text"
                    class="form-control alert alert-danger"
                    :value="id_error"
                    style="background-color: #f8d7da; margin-top: 2%"
                    readonly
                  />
                </div>
                <div class="col-md-6 mb-3" v-if="form.identification_type_id">
                  <label for="exampleInputEmail1" class="form-label"
                    >Id Number</label
                  >
                  <input
                    type="text"
                    id="id_type"
                    class="form-control"
                    v-model="form.id_number"
                  />
                  <input
                    v-if="id_error"
                    type="text"
                    class="form-control alert alert-danger"
                    :value="id_error"
                    style="background-color: #f8d7da; margin-top: 2%"
                    readonly
                  />
                </div>

                <div class="col-md-6 mb-3">
                  <label for="exampleInputEmail1" class="form-label"
                    >Gender</label
                  >
                  <select
                    id="gender"
                    v-model="form.gender"
                    class="form-control"
                  >
                    <option value="">Choose Gender</option>
                    <option value="M">MALE</option>
                    <option value="F">FEMALE</option>
                  </select>
                  <input
                    v-if="gender_error"
                    type="text"
                    class="form-control alert alert-danger"
                    :value="gender_error"
                    style="background-color: #f8d7da; margin-top: 2%"
                    readonly
                  />
                </div>
              </div>

              <!-- <hr class="mt-1"> -->
              <div class="col-sm-12">
                <div class="form-group d-inline">
                  <label>Adult</label>
                  <input
                    type="radio"
                    class="form-check-input"
                    value="1"
                    v-model="form.age_group_id"
                  />
                </div>
                <div class="form-group pl-3 d-inline" style="margin-left: 3%">
                  <label>Child</label>
                  <input
                    type="radio"
                    class="form-check-input"
                    value="2"
                    v-model="form.age_group_id"
                  />
                </div>
              </div>
              <hr class="mb-1 dotted-hr" />
              <div class="d-flex align-items-baseline">
                <div style="margin-right: 15px">
                  <p>Different pick-up point</p>
                </div>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="form.different_pickup_points"
                    />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>

              <div
                class="mx-3 p-3 drop-content"
                v-if="form.different_pickup_points"
              >
                <p class="mb-0">Select Pickup Point</p>
                <div
                  class="form-group"
                  v-for="(station, index) in pickUpStations"
                  :key="index"
                >
                  <input
                    type="radio"
                    :value="station.id"
                    v-model="form.pick_point_id"
                    @change="hinderVisibility(station.name)"
                  />

                  <label>{{ station.name }}({{ departureTime }})</label>
                </div>
              </div>
              <div class="d-flex align-items-baseline">
                <div style="margin-right: 35px">
                  <p class="">Different Drop point</p>
                  <!-- 210 -->
                </div>
                <div>
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="form.different_droup_points"
                    />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>

              <div
                class="mx-3 mb-3 p-3 drop-content"
                v-if="form.different_droup_points"
              >
                <p class="mb-0">Select Drop Point</p>
                <div
                  class="form-group"
                  v-for="(station, index) in dropOffStations"
                  :key="index"
                >
                  <input
                    type="radio"
                    :value="station.id"
                    v-model="form.drop_point_id"
                    @change="hinderVisibility(station.name)"
                  />
                  <label>{{ station.name }}</label>
                </div>
              </div>
              <hr />
            </form>
            <div class="d-flex">
              <div class="me-auto justify-content-start"></div>
              <div class="justify-content-end">
                <button
                  class="btn_1 btn-new"
                  style="
                    color: #ffffff;
                    background-color: var(--background-color);
                  "
                  @click="bookSeat()"
                >
                  Finish
                </button>
                <button
                  class="btn_1 btn-new"
                  style="color: #fff; background-color: #2b313e"
                  @click="moveToSearch()"
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
          <div v-else class="text-center alert alert-secondary">
            <h3>Please Select Seat First</h3>
          </div>
        </div>
      </div>
      <div class="home" v-else>
        <Loading />
      </div>
    </div>
  </main>
</template>

<script>
import jsonSeats from "@/views/station.json";
import Navbar from "@/components/Navbar.vue";
import Loading from "@/views/custom/Loading.vue";
export default {
  components: { Navbar, Loading },
  data() {
    return {
      departureTime: "",
      drawer: true,
      trip: {},
      isLoading: true,
      seats: jsonSeats,
      facilities: [],
      layout_type: [],
      status: false,
      selectedSeats: [],
      listOfNationalIds: [],
      listOfStations: [],
      error: "",
      first_name_error: "",
      second_name_error: "",
      phone_error: "",
      phone_long_error: "",
      email_error: "",
      id_type_error: "",
      id_error: "",
      gender_error: "",
      defaut_pick_point_id: "",
      defaut_drop_point_id: "",
      pickUpStations: [],
      dropOffStations: [],
    };
  },
  computed: {
    isScreen742AndBelow() {
      return window.innerWidth <= 742;
    },
  },
  methods: {
    dublicateRemover(stations) {
      let filteredStations = stations.filter(
        (obj, index, self) =>
          index ===
          self.findIndex((o) => o.id === obj.id && o.name === obj.name)
      );

      return filteredStations;
    },
    moveToSearch() {
      this.$router.push({ name: "Search" });
    },
    hinderVisibility(val) {
      console.log(val);

      //remove all items that are not by the name of val::
      $(document).ready(function () {
        //Do a loop (for each checkbox)
        $("input[type=radio]").each(function () {
          //Check if the box is checked
          var x = $(this).is(":checked");

          //if checkbox is NOT checked
          if (x === false) {
            //Hide the choice
            $(this).parent().hide();
          }
        });
      });
    },
    isLastItem(rowIndex, seatIndex) {
      return (
        rowIndex === this.seats.length - 1 &&
        seatIndex === this.seats[rowIndex].length - 1
      );
    },
    isLastRow(rowIndex) {
      // Check if it's the last row, and if the gap is at the sixth position from the end

      return (
        rowIndex === this.seats.length - 1 &&
        this.hasGapAtSixthPositionFromEnd()
      );
    },
    hasGapAtSixthPositionFromEnd() {
      // Check if the last row has a gap at the sixth position from the end
      if (this.seats.length > 0) {
        const lastRow = this.seats[this.seats.length - 1];

        // removing the gap::
        // const PreviousRow = this.seats[this.seats.length - 2];
        // PreviousRow.splice(5, 1);

        //
        if (
          lastRow.length >= 7 &&
          lastRow[lastRow.length - 7].space_type === "GAP"
        ) {
          return true;
        }
      }
      return false;
    },
    getTrip() {
      let id = this.$route.params.id;
      axios
        .get("/passenger/search-trips/" + id)
        .then((response) => {
          this.facilities = response.data.facilities;
          this.layout_type = response.data.layout_type;
          this.status = response.success;

          if (response.data.seats.length > 0) {
            this.seats = response.data.seats;

            this.seats.forEach((seat, index) => {
              seat.forEach((st, i) => {
                st.is_selected = false;
              });
            });
          } else {
            this.seats = response.data.oldSeats;
            var num = 0;
            if (this.seats.length % 4 === 0) {
            } else {
              num = this.seats.length % 4;
              this.seats = this.seats.slice(0, this.seats.length - num);
            }

            // Modify each seat and add gaps
            let modifiedSeats = [];
            let rowSeats = [];
            let seatCounter = 0;
            let gapCounter = 0;

            this.seats.forEach((seat, index) => {
              // Create a new seat object with modified properties
              let modifiedSeat = {
                id: seat.id,
                seat_number: seat.number.substring(1),
                space_type: "SEAT",
                seat_type: "Normal",
                seatRowPosition: Math.ceil((index + 1) / 5), // Calculate row position
                price: seat.price,
                is_active: seat.is_active,
                is_reserved: seat.is_reserved,
                is_selected: false,
              };

              // Add seat to the rowSeats array
              rowSeats.push(modifiedSeat);
              seatCounter++;
              if (seatCounter === 2) {
                // Add a gap seat
                rowSeats.push({
                  id: null,
                  seat_number: null,
                  space_type: "GAP",
                  seat_type: "Normal",
                  seatRowPosition: Math.ceil((index + 2) / 5), // Calculate row position
                  price: seat.price,
                  is_active: true,
                  is_reserved: false,
                  is_selected: false,
                });
                gapCounter++;
                seatCounter = 0;
              }
              if (gapCounter === 2 || index === this.seats.length - 1) {
                // Move the last seat to the previous row if the last row has only one seat
                if (index === this.seats.length - 1 && seatCounter === 1) {
                } else {
                  // Add the row to modifiedSeats and reset counters
                  modifiedSeats.push(rowSeats);
                }
                rowSeats = [];
                gapCounter = 0;
                modifiedSeats.push();
              }
            });

            if (num != 0) {
              var layout = response.data.layout_type;
              // var layout = {
              //   "left_side": 2,
              //   "right_side": 2,
              //   "front_seat_available": 1,
              //   "is_last_full_seat": 0
              // }
              //

              function addGapsToRow(row, numGapsToAdd, totSeats, seat) {
                if (row && row.length > 0) {
                  const seatRowPosition = row[0].seatRowPosition;

                  for (let i = 0; i < numGapsToAdd; i++) {
                    const newGap = {
                      id: null,
                      seat_number: null,
                      space_type: "GAP",
                      seat_type: "Normal",
                      seatRowPosition: seatRowPosition,
                      price: 0, // You can set the gap price as needed
                      is_active: true,
                      is_reserved: false,
                      is_selected: false,
                    };

                    // Insert the new gap seat after the first element
                    row.splice(1, 0, newGap);
                  }
                }
              }

              // check if is first seat::
              if (
                layout.front_seat_available == "1" &&
                layout.is_last_full_seat == "0"
              ) {
                //  check if num if greater or equal to 1::
                if (num >= 1) {
                  // getting the first row::
                  let firstRow = modifiedSeats[0];

                  // adding gaps::
                  addGapsToRow(firstRow, 6, this.seats.length, this.seats);

                  // testing
                  const seatNumberToSubtract = 3;
                  // Loop through the modified rows and update seat_number
                  modifiedSeats.forEach((row) => {
                    row.forEach((seat) => {
                      if (seat.id !== null) {
                        // Ensure it's a seat (not a GAP)
                        seat.seat_number = (
                          parseInt(seat.seat_number) - seatNumberToSubtract
                        ).toString();
                      }
                    });
                  });
                  //change the default first item
                  modifiedSeats[0][0].seat_number = "1";
                } else {
                  modifiedSeats = [];
                } // check if is last seat::
              } else if (
                layout.front_seat_available == "0" &&
                layout.is_last_full_seat == "1"
              ) {
                //  check if num if greater or equal to 1::
                if (num >= 1) {
                  //modify last row::

                  // remove gap in third position
                  let lastRow = modifiedSeats[modifiedSeats.length - 1];
                  let positionToRemove = 2; // Specify the position to remove

                  if (
                    lastRow &&
                    positionToRemove >= 0 &&
                    positionToRemove < lastRow.length
                  ) {
                    lastRow.splice(positionToRemove, 1);
                    // Create a new seat object to add to the last row
                    let newSeat = {
                      id: this.seats.length + 1, // Replace with the actual ID for the new seat
                      seat_number: this.seats.length + 1, // Replace with the seat number for the new seat
                      space_type: "SEAT",
                      seat_type: "Normal",
                      seatRowPosition: 4, // Use the last row's position or default to 1
                      price: 100, // You can set the price for the new seat as needed
                      is_active: true,
                      is_reserved: false,
                      is_selected: false,
                    };

                    lastRow.splice(4, 0, newSeat);
                  }
                } else {
                  modifiedSeats = [];
                } // check if is last seat::
                // check if both::
              } else if (
                layout.front_seat_available == "1" &&
                layout.is_last_full_seat == "1"
              ) {
                // check if num is greater or equal 2::
                if (num >= 2) {
                  //modify both::
                  //add gaps after the first seat
                  let firstRow = modifiedSeats[0];
                  addGapsToRow(firstRow, 6);

                  // handle the last row too::
                  let lastRow = modifiedSeats[modifiedSeats.length - 1];
                  let positionToRemove = 2; // Specify the position to remove

                  // testing
                  const seatNumberToSubtract = 3;
                  // Loop through the modified rows and update seat_number
                  modifiedSeats.forEach((row) => {
                    row.forEach((seat) => {
                      if (seat.id !== null) {
                        // Ensure it's a seat (not a GAP)
                        seat.seat_number = (
                          parseInt(seat.seat_number) - seatNumberToSubtract
                        ).toString();
                      }
                    });
                  });
                  //change the default first item
                  modifiedSeats[0][0].seat_number = "1";

                  if (
                    lastRow &&
                    positionToRemove >= 0 &&
                    positionToRemove < lastRow.length
                  ) {
                    lastRow.splice(positionToRemove, 1);
                    // Create a new seat object to add to the last row
                    let newSeat = {
                      id: this.seats.length + 1, // Replace with the actual ID for the new seat
                      seat_number: this.seats.length + 1, // Replace with the seat number for the new seat
                      space_type: "SEAT",
                      seat_type: "Normal",
                      seatRowPosition: 4, // Use the last row's position or default to 1
                      price: 100, // You can set the price for the new seat as needed
                      is_active: true,
                      is_reserved: false,
                      is_selected: false,
                    };

                    lastRow.splice(4, 0, newSeat);
                  }
                } else {
                  modifiedSeats = [];
                }
              }
            }

            this.seats = modifiedSeats;
          }
        })
        .catch((errors) => {})
        .finally(() => {
          setTimeout(
            function () {
              this.isLoading = false;
            }.bind(this),
            20
          );
        });
    },
    getNationalIds() {
      axios.get("passenger/id-types").then((response) => {
        this.listOfNationalIds = response.data.id_types;
      });
    },
    selectSeat(id) {
      this.seats = this.seats.map((seat) => {
        return seat.map((st) => {
          if (id === st.id) {
            st.is_selected = !st.is_selected;
            if (st.is_selected) {
              let booking = {
                seat_number: st.seat_number,
                seat_type: st.seat_type,
                fare: parseInt(st.price),
                first_name: "",
                last_name: "",
                age_group_id: 1,
                pick_point_id: this.defaut_pick_point_id,
                drop_point_id: this.defaut_drop_point_id,
                gender: "",
                email: "",
                identification_type_id: "",
                journey_type_id: localStorage.getItem("journey_type_id"),
                id_number: "",
                phone_number: "",
                journey_id: this.$route.params.id,
                different_pickup_points: "",
                different_droup_points: "",
              };
              this.selectedSeats.push(booking);
            } else {
              // Remove the item from selectedSeats if it exists
              this.selectedSeats = this.selectedSeats.filter(
                (booking) => booking.seat_number !== st.seat_number
              );
            }
          }
          return st;
        });
      });
    },
    bookSeat() {
      //clearing the errors:
      this.first_name_error = "";
      this.second_name_error = "";
      this.phone_error = "";
      this.phone_long_error = "";
      this.email_error = "";
      this.id_error = "";
      this.gender_error = "";
      this.identification_type_id = "";

      let dataToBeSent = {
        bookings: this.selectedSeats.map((selectedSeat) => ({
          // if (selectedSeat) {
          seat_number: selectedSeat.seat_number,
          seat_type: selectedSeat.seat_type,
          name: selectedSeat.first_name + " " + selectedSeat.last_name,
          age_group_id: selectedSeat.age_group_id,
          pick_point_id: selectedSeat.pick_point_id,
          drop_point_id: selectedSeat.drop_point_id,
          gender: selectedSeat.gender,
          email: selectedSeat.email,
          identification_type_id: selectedSeat.identification_type_id,
          journey_type_id: selectedSeat.journey_type_id,
          id_number: selectedSeat.id_number,
          phone_number: selectedSeat.phone_number,
          parent_number: selectedSeat.phone_number,
          journey_id: selectedSeat.journey_id,
          fare: selectedSeat.fare,
          // }
        })),
      };

      // Define a flag to keep track of whether all validations passed
      let allValid = true;

      for (let i = 0; i < this.selectedSeats.length; i++) {
        const form = this.selectedSeats[i];

        // Reset error messages for each seat
        this.first_name_error = "";
        this.second_name_error = "";
        this.phone_error = "";
        this.email_error = "";
        this.id_error = "";
        this.gender_error = "";
        this.identification_type_id = "";

        if (!form.first_name) {
          this.first_name_error = "First Name required!";
          allValid = false; // Set the flag to false if any validation fails
        }
        if (!form.last_name) {
          this.second_name_error = "Second Name required!";
          allValid = false;
        }

        if (!form.phone_number) {
          this.phone_error = "Phone Number required!";
          allValid = false;
        } else if (!/^0\d{9}$/.test(form.phone_number)) {
          this.phone_long_error =
            "10 digit number starting with 0 required! Format (071...)";
          allValid = false;
        } else if (form.phone_number.length != 10) {
          this.phone_long_error = "10 digit number required! Format (071...)";
          allValid = false;
        }

        if (!form.identification_type_id) {
          this.id_error = "ID Number is required!";
          allValid = false;
        }

        if (!form.id_number) {
          this.id_error = "ID Number is required!";
          allValid = false;
        }

        if (!form.gender) {
          this.gender_error = "Gender is required!";
          allValid = false;
        }
      }

      // Check if allValid is still true after all validations
      if (allValid) {
        // All validations passed, make the POST request
        axios
          .post("/passenger/book-ticket", dataToBeSent)
          .then((response) => {
            if (response.data.success == false) {
              var msg = response.data.message;
              this.error = `${msg}`;
              // Handle errors if needed
            } else {
              let bookings = response.data.bookings;
              localStorage.setItem("bookings", JSON.stringify(bookings));
              this.$router.push({ name: "CompleteBooking" });
            }
          })
          .catch((errors) => {
            this.error = errors.response.data.errors;
          });
      }
    },
  },
  created() {
    this.getTrip();
    this.getNationalIds();

    if (localStorage.getItem("pick_up_stations")) {
      console.log("passed here");
      this.dropOffStations = this.dublicateRemover(JSON.parse(
        localStorage.getItem("drop_off_points")
      ));
      this.pickUpStations = this.dublicateRemover(JSON.parse(
        localStorage.getItem("pick_up_stations")
      ));

       this.defaut_pick_point_id = this.pickUpStations[0].id;
      this.defaut_drop_point_id = this.dropOffStations[
        this.dropOffStations.length - 1
      ].id;
    } else {
      console.log("passed out");
      this.pickUpStations = this.dublicateRemover(JSON.parse(localStorage.getItem("stations")));
      this.dropOffStations = this.dublicateRemover(JSON.parse(localStorage.getItem("stations")));

      console.log(this.pickUpStations);
      this.defaut_pick_point_id = this.pickUpStations[0].id;
      this.defaut_drop_point_id = this.dropOffStations[
        this.dropOffStations.length - 1
      ].id;

      console.log(this.defaut_drop_point_id);

    }
    this.departureTime = JSON.parse(localStorage.getItem("departureTime"));

    // if (localStorage.getItem("stations") ) {
    //   this.listOfStations = JSON.parse(localStorage.getItem("stations"));
    //   this.pickUpStations = JSON.parse(localStorage.getItem("stations"));

    //   this.defaut_pick_point_id = this.listOfStations[0].id;
    //   this.defaut_drop_point_id = this.listOfStations[
    //     this.listOfStations.length - 1
    //   ].id;

    //   // log the journeys:
    //   console.log(this.listOfStations);
    // }
  },
};
</script>

<style scoped>
.dotted-hr {
  border: none;
  border-top: 1px dotted #000;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 99%;
}

.seat-space {
  padding: 30px 20px;
  border-style: solid;
  border-width: 1.3px;
  border-color: #eeeeee;
  border-radius: 0 0 20px 20px;
}

#hero {
  position: relative;
  height: 250px;
}

@media (max-width: 767px) {
  .intro_title {
    padding-bottom: 0rem !important;
  }

  a.btn_1,
  .btn_1 {
    width: 100%;
    padding: 10px 100px !important;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  #seat-map {
    border: 0px solid #eeeeee;
  }

  div.seatCharts-cell {
    width: 20% !important;
  }
}

.form-area label,
.form-area {
  color: #333 !important;
}

.form-group label {
  color: #333 !important;
}

.form-area p {
  color: #333 !important;
}

.slider {
  box-shadow: 0 0 0 2px #242a37, 0 0 4px #242a37;
}

.slider::before {
  background: #242a37;
}

hr {
  /*  background-color: var(--background-color2);*/
}

.complete-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1px;
  width: 100%;
  text-align: center;
}

.form-control,
.form-select {
  font-size: 14px;
  /* color: #fff !important; */
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  font-weight: bold;
}

.form-control input {
  padding-bottom: 40px;
  padding-top: 20px;
}

.drop-content {
  background: #ddd;
}

div.seatCharts-cell {
  border: 10px;
  border-color: #000000;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  font-size: 12px;
}

div.seatCharts-seat.available.first-class {
  /*color: white;
      background: radial-gradient(circle, #555 0%, #444 25%,#333 50%, #111 75%, #000 100%);*/
}

.hero-background-image {
  background-image: var(--background-image);
}
</style>
